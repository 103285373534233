import React from 'react';
import PropTypes from 'prop-types';

const SalesPage = props => {
  return (
      <div>

      </div>
  );
};

SalesPage.propTypes = {
  
};

export default SalesPage;
